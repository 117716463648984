

import {TrackingFeatureLabels} from "../types";

export default class Tile {
    readonly tileDom: HTMLElement;
    readonly position: number;
    readonly dreson: string | null;
    readonly featureTrackingLabels: TrackingFeatureLabels;

    constructor(tileDom: HTMLElement) {
        this.tileDom = tileDom;
        this.position = parseInt(tileDom.getAttribute("data-index")!) + 1;
        this.dreson = tileDom.getAttribute('data-dreson');
        this.featureTrackingLabels = JSON.parse(tileDom.getAttribute("data-feature-tracking-tile-labels")!);
    }

    setIsTrackedAsVisible() {
        this.tileDom.setAttribute("data-is-tracked-visible", String(true));
    }

    isTrackedAsVisible() {
        return this.tileDom.getAttribute("data-is-tracked-visible") === "true";
    }
}