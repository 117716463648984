import {Logger} from "./logger";
import ShopPromoLarge from "./large/shoppromo_large";
import ShopPromoSmall from "./small/shoppromo_small";
import PromoBrandShopPromo from "./brand/promo_brandshoppromo";
import BrandhubDiscoveryCard from "./brandhub/discovery_card";

/*                                                    */
window.o_global.eventQBus.emitModuleLoaded("ft3.promo.shoppromo");
window.o_promo_shoppromo = {
    ShopPromoLarge,
    ShopPromoSmall,
    PromoBrandShopPromo,
    BrandhubDiscoveryCard
}
Logger.log("Module ft3.promo.shoppromo module is loaded and the global o_promo_shoppromo object is initialized. The initialization is triggered directly in the template!");