

export default class BrandTile {
    readonly position: number;
    readonly dreson: string;
    readonly urlPath: string;
    readonly imageId: string;

    constructor(brandTileDom: Element, position: number) {
        brandTileDom.setAttribute("data-tile-position", position.toString())
        this.dreson = brandTileDom.getAttribute("data-dreson")!;
        this.imageId = brandTileDom.getAttribute("data-image-id")!;
        this.urlPath = brandTileDom.querySelector("a")!.getAttribute("href")!;
        this.position = position;
    }
}