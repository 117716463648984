import {TrackingFeature, TrackingFeatureLabels} from "../types";

export default class Slide {

    readonly slideDom: Element;
    private urlPath: string;
    readonly hasLever: boolean;
    readonly featureLabels: TrackingFeatureLabels;
    readonly leverTrackingPayload?: TrackingFeature;
    readonly position: number;


    constructor(slideDom: Element) {
        this.slideDom = slideDom;
        this.position = parseInt(slideDom.getAttribute("data-index")!) + 1;
        this.urlPath = slideDom.querySelector("a")!.getAttribute("href")!;
        this.featureLabels = JSON.parse(slideDom.getAttribute('data-feature-labels')!);
        this.hasLever = slideDom.querySelectorAll(".promo_shoppromo-large--lever-tag").length > 0;
        if (this.hasLever) {
            this.leverTrackingPayload = JSON.parse(slideDom.querySelector(".promo_shoppromo-large--lever-tag")!.getAttribute("data-tracking-payload")!);
        }
    }

    setIsTrackedAsVisible() {
        this.slideDom.setAttribute("data-is-tracked-visible", String(true));
    }

    isTrackedAsVisible() {
        return this.slideDom.getAttribute("data-is-tracked-visible") === "true";
    }

    registerClickEventHandler(eventHandler: (e: Event) => void) {
        this.slideDom.addEventListener("click", eventHandler);
    }

    registerLeverClickEventListener(eventHandler: (e: Event) => void) {
        const leverElement = this.slideDom.querySelector(".promo_shoppromo-large--lever-tag")!;
        leverElement.addEventListener("click", eventHandler);
    }


    /*           */
    click() {
        (this.slideDom as HTMLElement).click();
    }
}