import {CinemaChangeEvent, CinemaInitializedEvent, TrackingFeatureLabels} from "../types";
import Tile from "./tile";
import {TrackingService} from "./tracking_service";

export default class ShopPromoSmall {
    private readonly moduleId: string;
    private readonly featureOrder: number;
    private readonly masFeatureTrackingLabels: TrackingFeatureLabels
    private readonly featureId: string;
    private readonly featureIndex: number;
    readonly tiles: Tile[];
    private trackingService: TrackingService

    /*                                                            */
    constructor(shopPromoSmallContainerDom: Element) {
        this.moduleId = this.getModuleId(shopPromoSmallContainerDom);
        this.featureId = this.getFeatureId(shopPromoSmallContainerDom);
        this.featureIndex = this.getFeatureIndex(shopPromoSmallContainerDom);
        this.masFeatureTrackingLabels = this.getMasFeatureTrackingLabels(shopPromoSmallContainerDom);
        this.featureOrder = this.getFeatureOrder(shopPromoSmallContainerDom);
        this.trackingService = new TrackingService();
        this.tiles = Array.from(shopPromoSmallContainerDom.querySelectorAll(".pl_promo-card--link")).map((tileDom) => new Tile(tileDom as HTMLElement));

        this.tiles.forEach((tile) => {
            tile.tileDom.addEventListener("click", () => this.trackClickSmallShopPromo(tile))
        })


        window.o_global.eventQBus.on("pattern.carousel.mounted", (e) => {
            this.trackInitialLoadedSmallShopPromo(e)
            this.registerVisibleTrackObserver(e);
        });
        window.o_global.eventQBus.on("pattern.carousel.stage.changed", (e) => {
            this.trackPreviousSlideSmallShopPromo(e)
        });

        window.o_global.eventQBus.emit(
            "pattern.carousel.init",
            `.promo_shoppromo-small--container[data-feature-id="${this.featureId}"] .js_pl_carousel`
        )

    }

    trackedTilePositions: number[] = []

    registerVisibleTrackObserver(e: CinemaInitializedEvent): void {
        if (e.element.className.includes("promo_shoppromo-small--cinema") && e.element.parentElement!.parentElement!.id === this.moduleId) {
            const observer = new IntersectionObserver((entries) => {
                const tilesToTrack = entries
                    .filter((entry) => entry.isIntersecting)
                    .map((entry) => new Tile(entry.target as HTMLElement));

                if (tilesToTrack.length > 0) {
                    const action = this.determineAction(tilesToTrack[0]);
                    this.trackedTilePositions.push(...tilesToTrack.map(tile => tile.position));
                    if (action == "scroll") {
                        this.trackingService.sendMiniAction(this.featureId, tilesToTrack, action);
                    } else {
                        this.trackingService.sendSmallShoppromoSlideTrackingEvent(this.featureOrder, this.featureIndex, this.featureId, this.tiles.length, tilesToTrack, action, this.masFeatureTrackingLabels);
                    }
                }
                tilesToTrack.forEach(tile => observer.unobserve(tile.tileDom));
            }, {});

            this.tiles.forEach((tile) => {
                observer.observe(tile.tileDom);
            })
        }
    }

    private determineAction(tile: Tile): "scroll" | "next" | "previous" {
        /*                                                                                 */
        if (tile.position % 2 != 0 && this.trackedTilePositions.some(position => position % 2 != 0)) {
            return "next";
        }

        /*                                                                                  */
        if (tile.position % 2 == 0 && this.trackedTilePositions.some(position => position % 2 == 0)) {
            /*                                                                   */
            if (this.trackedTilePositions.some(position => position % 2 == 0 && position < tile.position)) {
                return "next";
            } else {
                return "previous";
            }
        }

        /*                                                             */
        return "scroll";
    }

    trackInitialLoadedSmallShopPromo(e: CinemaInitializedEvent): void {
        if (e.element.className.includes("promo_shoppromo-small--cinema") && e.element.parentElement!.parentElement!.id === this.moduleId && !e.element.parentElement?.getAttribute("data-tracked")) {
            e.element.parentElement?.setAttribute("data-tracked", String(true));
            this.trackingService.sendSmallShoppromoInitialLoadedTrackingEvent(this.featureOrder, this.featureIndex, this.featureId, this.tiles.length, this.tiles, this.masFeatureTrackingLabels);
        }
    }

    trackPreviousSlideSmallShopPromo(e: CinemaChangeEvent): void {
        if (e.element.className.includes("promo_shoppromo-small--cinema") && !e.scrollingRight && e.element.parentElement!.parentElement!.id === this.moduleId) {
            this.trackingService.sendSmallShoppromoSlideTrackingEvent(this.featureOrder, this.featureIndex, this.featureId, this.tiles.length, [], "previous", this.masFeatureTrackingLabels);
        }
    }

    trackClickSmallShopPromo(tile: Tile): void {
        this.trackingService.sendSmallShoppromoClickTrackingEvent(this.featureOrder, this.featureIndex, this.featureId, this.tiles.length, tile, this.masFeatureTrackingLabels);
    }

    private getFeatureOrder(shopPromoSmallContainerDom: Element): number {
        const dataFeatureOrder = shopPromoSmallContainerDom.closest('[data-feature-order]')?.getAttribute('data-feature-order');
        return dataFeatureOrder ? parseInt(dataFeatureOrder, 0) : -1;
    }

    private getMasFeatureTrackingLabels(shopPromoSmallContainerDom: Element): TrackingFeatureLabels {
        const dataFeatureTrackingLabels = shopPromoSmallContainerDom.closest('[data-feature-order]')?.getAttribute('data-feature-tracking-labels');
        return dataFeatureTrackingLabels ? JSON.parse(dataFeatureTrackingLabels) : {};
    }

    private getFeatureId(shopPromoSmallContainerDom: Element): string {
        return shopPromoSmallContainerDom.getAttribute('data-feature-id')!;
    }

    private getFeatureIndex(shopPromoSmallContainerDom: Element): number {
        return parseInt(shopPromoSmallContainerDom.getAttribute('data-feature-index')!, 0);
    }

    private getModuleId(shopPromoSmallContainerDom: Element) {
        return shopPromoSmallContainerDom.parentElement!.getAttribute('id')!;
    }
}