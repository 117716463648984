import {DiscoveryCardTileType} from "../types";

export default class DiscoveryCardTile {
    readonly position: number;
    readonly dreson: string;
    readonly urlPath: string;
    readonly imageId: string;
    readonly tileType: DiscoveryCardTileType;

    constructor(tileDom: Element, position: number) {
        this.dreson = tileDom.getAttribute("data-dreson")!;
        this.imageId = tileDom.getAttribute("data-image-id")!;
        if (tileDom instanceof HTMLAnchorElement) {
            this.urlPath = tileDom.getAttribute("href")!
        } else {
            this.urlPath = tileDom.querySelector("a")!.getAttribute("href")!;
        }
        this.position = position;
        if (tileDom.classList.contains("promo_brandhub_discovery_card__item-large")) {
            this.tileType = "LargeTile"
        } else if (tileDom.classList.contains("promo_brandhub_discovery_card__item-small")) {
            this.tileType = "SmallTile"
        } else if (tileDom.classList.contains("promo_brandhub_discovery_card__cta-button")) {
            this.tileType = "MehrEntdecken"
        } else {
            this.tileType = "unknown"
            /*                                  */
            console.log("cannot determine tile type from classList", tileDom.classList)
        }
        tileDom.setAttribute("data-tile-position", position.toString())
    }
}